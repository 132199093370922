import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, PlatformsTable, AffiliateDisclosure, CurrencyRatesFAQ, NonCustodialCard } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Pane } from 'evergreen-ui';
import { SIGN_UP_BONUS_HTML, slugToSymbolMap, currentMonthShort } from '../utils/constants';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/

const Page = ({ data, location }) => {
    const { pathname } = location;
    const page = data.ghostPage
    const { name, symbol, largeImage, isStablecoin } = slugToSymbolMap[pathname];
    const contentBody = `Compare the best ${symbol} (${name}) interest rates and withdrawal fees of ${currentMonthShort} 2024, from the top crypto interest accounts. Comparison of the best CeFi platforms to earn on ${symbol}, so you can maximize your ${name} APY / APR, and earn yield.`;
    // const description = `Compare the best ${symbol} (${name}) interest rates to earn yield. Look at APY, withdrawal fees, and more, across the top crypto interest accounts.`
    const description =`Compare the best ${symbol} (${name}) interest rates and withdrawal fees, from the top crypto interest accounts. Find the best place to earn on ${symbol}.`
    let metaTitle = page.meta_title;
    if (metaTitle.length < 50) {
        metaTitle += ` | ${currentMonthShort} 2024`;
    } else if (metaTitle.length < 54) {
        metaTitle += ' | 2024';
    }

    return (
        <>
            <MetaData
                data={data}
                description={description}
                location={location}
                type="website"
                image={largeImage}
                smallImageMeta={true}
                title={metaTitle}
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        <Pane className="content-title image-heading-container">
                            <img className="crypto-icon" src={largeImage} alt={`${symbol} (${name}) Logo`} width={36} height={36} />
                            <h1 className="image-heading">{page.title} of {currentMonthShort} 2024</h1>
                        </Pane>
                        <section className="content-body load-external-scripts post-card-excerpt">
                            <p>
                                {contentBody}
                            </p>
                        </section>
                        <PlatformsTable symbol={symbol} pathname={pathname} />
                        <NonCustodialCard />
                        <AffiliateDisclosure />
                        <CurrencyRatesFAQ symbol={symbol} currency={name} isStablecoin={isStablecoin} />
                        <section
                            className="post-full-content content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: SIGN_UP_BONUS_HTML }}
                        />
                    </article>
                </div>
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
